import { IoMoonOutline } from "react-icons/io5";
import { HiOutlineArrowLongRight } from "react-icons/hi2";

function Card1(props: any) {
  return (
    <div className="StayDetailScreen-box7">
      <div className="StayDetailScreen-box8">
        <div className="StayDetailScreen-txt4">CHECK-IN</div>
        <div className="StayDetailScreen-box9">
          <div>Monday</div>
          <div className="StayDetailScreen-txt5">20</div>
          <div>Jan 2024</div>
        </div>
        <div className="StayDetailScreen-txt4">From 3:00 pm</div>
      </div>
      <div className="StayDetailScreen-box8" style={{ flex: 1 }}>
        <div>
          <IoMoonOutline className="StayDetailScreen-icon3" />
        </div>
        <div className="StayDetailScreen-txt4">2 Nights</div>
        <div>
          <HiOutlineArrowLongRight className="StayDetailScreen-icon2" />
        </div>
      </div>
      <div className="StayDetailScreen-box8">
        <div className="StayDetailScreen-txt4">CHECK-IN</div>
        <div className="StayDetailScreen-box9">
          <div>Monday</div>
          <div className="StayDetailScreen-txt5">20</div>
          <div>Jan 2024</div>
        </div>
        <div className="StayDetailScreen-txt4">From 3:00 pm</div>
      </div>
    </div>
  );
}

export default Card1;
