import "./styles.scss";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Banners from "../../components/banners";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";
import { Form, Input } from "antd";
import Okay from "../../assets/images/okay.svg";
import Vgood from "../../assets/images/vgood.svg";
function RatingScreen(props: any) {
  const Navigate = useNavigate();
  const getOrientation = () => window.screen.orientation.type;
  const [orientation, setOrientation] = useState(getOrientation());
  const [selected, setSelected] = useState<any>(null);

  useEffect(() => {
    window.addEventListener("resize", updateOrientation);
    return () => {
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  const updateOrientation = (event: any) => {
    setOrientation(getOrientation());
  };
  return (
    <div>
      <Container fluid>
        <Row>
          <Col
            sm={orientation === "portrait-primary" ? 12 : 8}
            md={orientation === "portrait-primary" ? 12 : 8}
            style={{ margin: 0, padding: 0 }}
          >
            <div className="box1">
              <div className="RatingScreen-box1">
                {selected ? null : (
                  <>
                    <div className="box3">
                      <FaCheckCircle className="Success-icon" />
                    </div>
                    <br />
                    <div className="text-type-1">You’ve Checked-out</div>
                    <br />
                    <div className="text-type-2">
                      <div style={{ width: "70%" }}>
                        Thank you for staying with us. We hope to see you again
                        soon. Access to your room, as well as your key card, has
                        been disabled.
                      </div>
                    </div>
                    <br />
                    <hr />
                  </>
                )}

                <div className="text-type-1">How was your stay ?</div>
                <br />
                <Row>
                  <Col sm={4} onClick={() => setSelected(1)}>
                    <div className="RatingScreen-box2">
                      <img
                        src={Okay}
                        className="RatingScreen-smile"
                        alt="smile"
                      />
                      <div className="text-type-2">okay</div>
                    </div>
                  </Col>
                  <Col sm={4} onClick={() => setSelected(2)}>
                    <div className="RatingScreen-box2">
                      <img
                        src={Okay}
                        className="RatingScreen-smile"
                        alt="smile"
                      />
                      <div className="text-type-2">good</div>
                    </div>
                  </Col>
                  <Col sm={4} onClick={() => setSelected(3)}>
                    <div className="RatingScreen-box2">
                      <img
                        src={Vgood}
                        className="RatingScreen-smile"
                        alt="smile"
                      />
                      <div className="text-type-2">Nothing but great!</div>
                    </div>
                  </Col>
                </Row>
                {selected ? (
                  <div className="RatingScreen-box1">
                    <div className="text-type-2">
                      <div style={{ width: "60%" }}>
                        Your feedback matters! Please share your insights to
                        help us improve your experience.
                      </div>
                    </div>
                    <br />
                    <Form>
                      <Form.Item>
                        <Input.TextArea rows={6} placeholder="TYPE HERE" />
                      </Form.Item>
                    </Form>
                    <br />
                    <Row>
                      <Col sm={2} xs={1}>
                        <div
                          className="back-button"
                          onClick={() => Navigate(-1)}
                        >
                          <FaArrowLeft />
                        </div>
                      </Col>
                      <Col sm={8} xs={11}>
                        <div
                          className="button-primary"
                          onClick={() => Navigate("/")}
                        >
                          SEND FEEDBACK
                        </div>
                      </Col>

                      <Col sm={2}></Col>
                    </Row>
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
          {orientation === "portrait-primary" ? null : (
            <Col sm={4} md={4} style={{ margin: 0, padding: 0 }}>
              <div className="box2">
                <Banners />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default RatingScreen;
