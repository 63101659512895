import React from "react";
import Clock from "react-live-clock";
import moment from "moment";
function TimeBar() {
  return (
    <div className="HomeScreen-TimeBar">
      <div className="HomeScreen-row">
        <div className="HomeScreen-txt2">17 </div>&nbsp;&nbsp;
        <div className="HomeScreen-txt7">
          partly <br /> Cloud
        </div>
      </div>
      <div className="HomeScreen-TimeBarline" />
      <div className="HomeScreen-txt2">
        <Clock format={"h:mm a"} ticking={true} />
      </div>
      &nbsp;&nbsp;&nbsp;
      <div className="HomeScreen-txt5">
        {moment(new Date()).format("ddd, DD MMM")}
      </div>
    </div>
  );
}

export default TimeBar;
