import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./styles.scss";
import Step1 from "./step1";
import Step2 from "./step2";
function FaceIdScreen() {
  const Navigate = useNavigate();
  const { status } = useParams();
  const [step, setStep] = useState(1);
  const [image, setImage] = useState(null);
  const [validation, setValidation] = useState<any>(null);

  const step1Action = (path: any) => {
    setImage(path);
    setStep(2);
    setTimeout(() => {
      setValidation(status);
      step2Action();
    }, 5000);
  };

  const clearFace = () => {
    setValidation(null);
    setStep(1);
  };

  const step2Action = () => {
    setTimeout(() => {
      if (status === "success") {
        Navigate("/stayDetails/1");
      }
    }, 1500);
  };

  return (
    <div className="FaceIdScreen">
      {step === 1 ? (
        <Step1
          image={image}
          onChange={(value: any) => step1Action(value)}
          goback={() => Navigate(-1)}
        />
      ) : null}
      {step === 2 ? (
        <Step2
          image={image}
          goback={() => clearFace()}
          validation={validation}
          notMatch={() => Navigate("/scanPassport")}
        />
      ) : null}
    </div>
  );
}

export default FaceIdScreen;
