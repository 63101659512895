import "./styles.scss";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Banners from "../../components/banners";
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import EmailInvoice from "./components/emailInvoice";
import CardAuth from "../../components/cardAuth";
import SuccessPopup from "../../components/successPopup";
function StaySummaryScreen(props: any) {
  const Navigate = useNavigate();
  const getOrientation = () => window.screen.orientation.type;
  const [orientation, setOrientation] = useState(getOrientation());
  const [step, setStep] = useState(1);
  const [emailing, setEmailing] = useState(false);
  const [payment, setPayment] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", updateOrientation);
    return () => {
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  const updateOrientation = (event: any) => {
    setOrientation(getOrientation());
  };

  const onClickNext = () => {
    setPayment(true);
    setTimeout(() => {
      setPayment(false);
      setSuccess(true);
      gotoNext();
    }, 3000);
  };

  const gotoNext = () => {
    setTimeout(() => {
      setSuccess(false);
      Navigate("/rating");
    }, 2000);
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col
            sm={orientation === "portrait-primary" ? 12 : 8}
            md={orientation === "portrait-primary" ? 12 : 8}
            style={{ margin: 0, padding: 0 }}
          >
            <div className="box1">
              {step === 1 ? (
                <Step1
                  sendEmail={() => setEmailing(true)}
                  goBack={() => Navigate(-1)}
                  onChange={() => setStep(2)}
                />
              ) : null}
              {step === 2 ? (
                <Step2
                  goBack={() => setStep(1)}
                  onChange={() => onClickNext()}
                />
              ) : null}
            </div>
          </Col>
          {orientation === "portrait-primary" ? null : (
            <Col sm={4} md={4} style={{ margin: 0, padding: 0 }}>
              <div className="box2">
                <Banners />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      {emailing ? (
        <EmailInvoice
          visible={emailing}
          onClose={() => setEmailing(false)}
          onChange={(err: any) => {
            console.log("err");
          }}
        />
      ) : null}
      {payment ? (
        <CardAuth
          visible={payment}
          onChange={(err: any) => {
            console.log("err");
          }}
        />
      ) : null}
      {success ? (
        <SuccessPopup
          visible={success}
          onChange={(err: any) => {
            console.log("err");
          }}
        />
      ) : null}
    </div>
  );
}

export default StaySummaryScreen;
