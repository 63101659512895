function Item(props: any) {
  return (
    <div className="staySummaryScreen-item">
      <div>
        <div className="text-type-4">19 Jul, 2023 - 3.30 pm</div>
        <div className="text-type-3">In-Room Dining</div>
      </div>
      <div className="text-type-4">Cheque No: 0AK01235</div>
      <div className="text-type-3">S$ 80.50</div>
    </div>
  );
}

export default Item;
