import { Form, Input } from "antd";

function Step3(props: any) {
  return (
    <div className="PassportScanScreen-Step2">
      <div className="text-type-1">Enter Your Booking Details</div>
      <div className="text-type-2">
        Type in your booking details to get started
      </div>
      <div className="PassportScanScreen-box4">
        <Form>
          <div className="Form-item">
            <Form.Item noStyle>
              <Input placeholder="Booking ID *" size="large" bordered={false} />
            </Form.Item>
          </div>
          <br />
          <div className="Form-item">
            <Form.Item noStyle>
              <Input placeholder="Last Name *" size="large" bordered={false} />
            </Form.Item>
          </div>
        </Form>
      </div>
      <div style={{ width: "60%" }} onClick={() => props.onChange()}>
        <div className="button-primary">CONTINUE</div>
      </div>
    </div>
  );
}

export default Step3;
