import PassportVerify from "../../../assets/images/passport-verify.png";
function Step2(props: any) {
  return (
    <div className="PassportScanScreen-Step2">
      <div className="text-type-1">Verifying Your Identity with Ease</div>
      <div className="text-type-2">
        <div style={{ width: "70%" }}>
          We’re matching your face with the info on your document to make sure
          everything lines up.
        </div>
      </div>
      <div className="PassportScanScreen-box1">
        <img
          src={PassportVerify}
          className="PassportScanScreen-scanImg2"
          alt="passport"
        />
      </div>
      <div className="PassportScanScreen-txt3">
        A Premium City-Centre Destination With Attitude
      </div>
    </div>
  );
}

export default Step2;
