import { Checkbox, Form, Input } from "antd";
import { Col, Row } from "react-bootstrap";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";

function Step2(props: any) {
  return (
    <>
      <div className="text-type-1">Card Confirmation</div>
      <div className="text-type-2">
        Your card will be authorised for incidentals and real-time bill
        payments.
      </div>
      <br />
      <div className="reviewScreen-box1">
        <div className="reviewScreen-box3">
          <div className="text-type-2">Incidental amount</div>
          <div className="text-type-1">S$1000</div>
        </div>
      </div>
      <br />
      <div className="FaceIdScreen-box2">
        <Checkbox>
          I consent to the temporary hold of an incidental payment on my credit
          card as outlined in the <a href="/">Terms & Conditions</a>
        </Checkbox>
      </div>

      <br />
      <div>
        <Row>
          <Col sm={2} xs={1}>
            <div className="back-button" onClick={() => props.onBack()}>
              <FaArrowLeft />
            </div>
          </Col>
          <Col sm={8} xs={12}>
            <div className="button-primary" onClick={() => props.onChange()}>
              <div>MOVE TO PAYMENT</div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Step2;
