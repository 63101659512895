import "./styles.scss";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Banners from "../../components/banners";
import { FaCheckCircle } from "react-icons/fa";
import KeyCardActivation from "../../components/keyCardActivation";
import ErrorPopup from "../../components/errorPopup";
function SuccesScreen(props: any) {
  const Navigate = useNavigate();
  const { status } = useParams();
  const getOrientation = () => window.screen.orientation.type;
  const [orientation, setOrientation] = useState(getOrientation());
  const [keyCard, setkeyCard] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", updateOrientation);
    return () => {
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  const updateOrientation = (event: any) => {
    setOrientation(getOrientation());
  };

  const onClickNext = () => {
    if (status === "1") {
      Navigate("/");
    } else {
      clickContinue();
    }
  };

  const clickContinue = () => {
    setkeyCard(true);
    setTimeout(() => {
      setkeyCard(false);
      setError(true);
    }, 5000);
  };

  const ClickNext = () => {
    Navigate("/success/1");
    setError(false);
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col
            sm={orientation === "portrait-primary" ? 12 : 8}
            md={orientation === "portrait-primary" ? 12 : 8}
            style={{ margin: 0, padding: 0 }}
          >
            <div className="box1">
              <div className="SuccesScreen-box1">
                <FaCheckCircle className="Success-icon" />
                <br />
                <div className="text-type-1">
                  {status === "1" ? "Key Card Activated" : "Check-in Completed"}
                </div>
                <br /> <br />
                <div className="SuccesScreen-txt2">Your Room No</div>
                <div className="SuccesScreen-txt1">303</div>
                <br />
                <div className="text-type-2">
                  <div>
                    {status === "1"
                      ? "   You can now use your key to unlock your room. Enjoy your stay."
                      : " Please generate the door key to unlock your room"}
                  </div>
                </div>
              </div>
              <br />
              <div>
                <Row>
                  <Col sm={2} xs={1}></Col>
                  <Col sm={8} xs={11}>
                    <div
                      className="button-primary"
                      onClick={() => onClickNext()}
                    >
                      <div>
                        {status === "1" ? "BACK TO HOME" : "GENERATE KEY"}
                      </div>
                    </div>
                  </Col>
                  <Col sm={2}></Col>
                </Row>
              </div>
            </div>
          </Col>
          {orientation === "portrait-primary" ? null : (
            <Col sm={4} md={4} style={{ margin: 0, padding: 0 }}>
              <div className="box2">
                <Banners />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      {keyCard ? (
        <KeyCardActivation
          visible={keyCard}
          onClose={() => setkeyCard(false)}
          onChange={(err: any) => {
            console.log("err");
          }}
        />
      ) : null}
      {error ? (
        <ErrorPopup
          visible={error}
          onClose={() => setError(false)}
          onChange={(err: any) => ClickNext()}
        />
      ) : null}
    </div>
  );
}

export default SuccesScreen;
