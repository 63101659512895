import { Modal } from "antd";
import CardActive from "../../assets/images/card_activate.mp4";
import { IoWifiOutline } from "react-icons/io5";
function KeyCardActivation(props: any) {
  return (
    <Modal
      open={props.visible}
      footer={false}
      closable={false}
      centered
      width={650}
    >
      <div className="KeyCardActivation-Box">
        <div className="text-type-1">Activate your Key Card</div>
        <div className="text-type-2">
          <div style={{ width: "100%" }}>
            Place a key card in the space marked <IoWifiOutline size={25} />
            <br />
            on the left side of the kiosk to activate your key.
          </div>
        </div>
        <video className="KeyCardActivation-video" autoPlay loop muted>
          <source src={CardActive} type="video/mp4" />
          <source src={CardActive} type="video/ogg" />
        </video>
      </div>
    </Modal>
  );
}

export default KeyCardActivation;
