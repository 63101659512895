import { Form, Input } from "antd";
import { Col, Row } from "react-bootstrap";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";

function Step2(props: any) {
  return (
    <>
      <div className="text-type-1">Identity Verification</div>
      <div className="text-type-2">
        Please review and update your details as needed.
      </div>
      <br />
      <div className="reviewScreen-box1">
        <div className="text-type-4">Primary Guest</div>
        <div className="reviewScreen-box2">
          <div className="text-type-3">Steven Bradley</div>
          <div>
            <FaCheckCircle className="Success-icon2" />
          </div>
        </div>
        <br />
        <div className="text-type-4">Accompanying Guest</div>
        <div className="reviewScreen-box2">
          <div className="text-type-3">Alice Bradley</div>
          <div>
            <FaCheckCircle className="Success-icon2" />
          </div>
        </div>
      </div>
      <br />
      <div>
        <Row>
          <Col sm={2} xs={1}>
            <div className="back-button" onClick={() => props.onBack()}>
              <FaArrowLeft />
            </div>
          </Col>
          <Col sm={8} xs={12}>
            <div className="button-primary" onClick={() => props.onChange()}>
              <div>MOVE TO PAYMENT</div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Step2;
