import { Col, Row } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import Timer from "../../assets/images/Timer.gif";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
function Step2(props: any) {
  return (
    <>
      <div className="FaceIdScreen-box1">
        <div className="FaceIdScreen-box6">
          {props.validation ? null : (
            <div>
              <img src={Timer} className="FaceIdScreen-Timer" alt="timer" />
            </div>
          )}
        </div>
        <div className="FaceIdScreen-box3">
          {props.validation === "success" ? null : (
            <div className="FaceIdScreen-txt7">LOOK HERE</div>
          )}
          <br />
          <div className="FaceIdScreen-box4">
            <div className="FaceIdScreen-box5">
              <img
                src={
                  "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg"
                }
                className="FaceIdScreen-faceImage"
              />
            </div>
          </div>
          {props.validation ? (
            <div className="FaceIdScreen-alerBox">
              <div>
                {props.validation === "success" ? (
                  <IoCheckmarkCircle size={30} color="#2bd959" />
                ) : (
                  <IoCloseCircle size={30} color="#e82c39" />
                )}
              </div>
              <div className="FaceIdScreen-txt8">
                {props.validation === "success"
                  ? "Facial match successful!"
                  : "Facial match Faild!"}
              </div>
            </div>
          ) : null}
        </div>

        <div>
          <Row>
            <Col sm={3} md={3} xs={1}>
              <div className="back-button" onClick={() => props.goback()}>
                <FaArrowLeft />
              </div>
            </Col>
            <Col sm={6} md={6} xs={11}>
              <div className="FaceIdScreen-txt7">
                Take a look at the top panel of the device.
              </div>
            </Col>
            <Col sm={3} md={3}>
              <div
                className="FaceIdScreen-button2"
                onClick={() => props.notMatch()}
              >
                If the face is not matched
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Step2;
