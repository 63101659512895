import "./styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import videos from "../../assets/images/bgvid1.mp4";
import Logo from "../../assets/images/Pullman-singapore-logo.png";
import Checkin from "../../assets/images/checkin.svg";
import Checkout from "../../assets/images/checkout.svg";
import Doorkey from "../../assets/images/doorkey.svg";
import Feedback from "../../assets/images/feedback.svg";
import { HiArrowLongRight } from "react-icons/hi2";

import TimeBar from "./components/timeBar";

function HomeScreen() {
  const Navigate = useNavigate();
  return (
    <div className="video-box">
      <video className="video-play" autoPlay loop muted>
        <source src={videos} type="video/mp4" />
        <source src={videos} type="video/ogg" />
      </video>
      <div className="video-overlay"></div>
      <div className="HomeScreen-content">
        <TimeBar />
        <br />
        <div className="HomeScreen-box2">
          <Container fluid>
            <Row>
              <Col xs={12} md={7} sm={10} xl={6}>
                <div className="HomeScreen-txt1">Welcome!</div>
                <div className="HomeScreen-txt3">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                </div>
                <br /> <br />
                <div
                  className="HomeScreen-checkInBox"
                  onClick={() => Navigate("/faceID/success")}
                >
                  <div className="HomeScreen-row">
                    <div>
                      <img
                        src={Checkin}
                        className="HomeScreen-CheckIcon"
                        alt="Checkin"
                      />
                    </div>
                    <div className="HomeScreen-txt4">Begin your check-in</div>
                  </div>
                  <div>
                    <HiArrowLongRight className="HomeScreen-CheckIcon2" />
                  </div>
                </div>
              </Col>
            </Row>
            <div style={{ margin: "4vw" }} />
            <Row>
              <Col sm={2} md={2} xs={3}>
                <div
                  className="HomeScreen-box4"
                  onClick={() => Navigate("/checkout")}
                >
                  <img
                    src={Checkout}
                    className="HomeScreen-icon3"
                    alt="Checkout"
                  />
                  <div className="HomeScreen-txt6">Checkout</div>
                  <HiArrowLongRight className="HomeScreen-icon4" />
                </div>
              </Col>
              <Col sm={2} md={2} xs={3}>
                <div className="HomeScreen-box4">
                  <img
                    src={Doorkey}
                    className="HomeScreen-icon3"
                    alt="Doorkey"
                  />
                  <div className="HomeScreen-txt6">Door Key</div>
                  <HiArrowLongRight className="HomeScreen-icon4" />
                </div>
              </Col>
              <Col sm={2} md={2} xs={3}>
                <div
                  className="HomeScreen-box4"
                  onClick={() => Navigate("/rating")}
                >
                  <img
                    src={Feedback}
                    className="HomeScreen-icon3"
                    alt="Feedback"
                  />
                  <div className="HomeScreen-txt6">Feedback</div>
                  <HiArrowLongRight className="HomeScreen-icon4" />
                </div>
              </Col>
              <Col sm={6} md={6} xs={3}>
                <div className="HomeScreen-box3">
                  <img
                    src={Logo}
                    className="HomeScreen-logo"
                    alt="Pullman-singapore"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
