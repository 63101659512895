import { PiPoliceCarLight } from "react-icons/pi";
import { CiWallet } from "react-icons/ci";

function Card3(props: any) {
  return (
    <div className="StayDetailScreen-box4">
      <div className="StayDetailScreen-box5">
        <div>
          <PiPoliceCarLight className="StayDetailScreen-icon1" />
        </div>
        <div className="StayDetailScreen-txt3">Palm Court Room</div>
      </div>
      <div className="StayDetailScreen-devider" />
      <div className="StayDetailScreen-box5">
        <div>
          <CiWallet className="StayDetailScreen-icon1" />
        </div>
        <div className="StayDetailScreen-txt3">Room No 303</div>
      </div>
    </div>
  );
}

export default Card3;
