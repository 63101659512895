import "./styles.scss";
import Slider from "react-slick";
import Banner1 from "../../assets/images/banner1.png";
function Banners(props: any) {
  const Slidesettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
  };
  return (
    <div className="Banners-Box">
      <Slider {...Slidesettings}>
        <div>
          <div
            className="Banners-item"
            style={{ backgroundImage: `url(${Banner1})` }}
          >
            <div className="Banners-itembox1">
              <div className="Banners-txt1">Jingle, Shop & Stay</div>
              <div className="Banners-txt2">
                Jingle, Shop & Stay at Pullman Singapore Orchard: S$499++ per
                room per night for 2 persons
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="Banners-item"
            style={{ backgroundImage: `url(${Banner1})` }}
          >
            <div className="Banners-itembox1">
              <div className="Banners-txt1">Jingle, Shop & Stay</div>
              <div className="Banners-txt2">
                Jingle, Shop & Stay at Pullman Singapore Orchard: S$499++ per
                room per night for 2 persons
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default Banners;
