import { Col, Row } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
function Step2(props: any) {
  return (
    <>
      <div className="text-type-1">Card Confirmation</div>
      <div className="text-type-2">
        Kindly add or use the card on file for payment of your dues.
      </div>
      <br />
      <div className="StaySummaryScreen-box1">
        <br />
        <div className="text-type-4">Card on File</div>
        <div style={{ marginBottom: 10 }} />
        <div className="StaySummaryScreen-box6">
          <div>
            <div className="text-type-3">Steven Bradley</div>
            <br />
            <div className="text-type-2">VISA **** **** **** 2541</div>
          </div>
          <div>
            <FaCheckCircle className="StaySummaryScreen-check" />
          </div>
        </div>
        <br />
        <div style={{ width: "20%" }}>
          <div className="button-add">ADD CARD</div>
        </div>
      </div>
      <br />
      <div>
        <Row>
          <Col sm={2} xs={1}>
            <div className="back-button" onClick={() => props.goBack()}>
              <FaArrowLeft />
            </div>
          </Col>
          <Col sm={8} xs={12} onClick={() => props.onChange()}>
            <div className="button-primary">
              <div>MOVE TO PAYMENT</div>
            </div>
          </Col>

          <Col sm={2} xs={12}></Col>
        </Row>
      </div>
    </>
  );
}

export default Step2;
