import { Modal } from "antd";
import CardActive from "../../assets/images/card_activate.mp4";
import { IoWifiOutline } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
function SuccessPopup(props: any) {
  return (
    <Modal
      open={props.visible}
      footer={false}
      closable={false}
      centered
      width={650}
    >
      <div className="KeyCardActivation-Box">
        <FaCheckCircle className="Success-icon" />
        <br />
        <div className="text-type-1">Card Authorization Successful</div>
        <br />
        <div className="text-type-2">
          Thank you! Your card authorization has been successfully processed.
        </div>
      </div>
    </Modal>
  );
}

export default SuccessPopup;
