import { Col, Row } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import Item from "./item";
function Step1(props: any) {
  return (
    <>
      <div className="text-type-1">Hi! Stephen Bradley,</div>
      <div className="text-type-2">
        Hope you’re having a great time with us!
      </div>
      <br />
      <div className="StaySummaryScreen-box1">
        <Row>
          <Col sm={4} xs={12}>
            <div className="StaySummaryScreen-box2">
              <div className="text-type-4">Amount Due</div>
              <div className="text-type-3">$1482.00</div>
            </div>
          </Col>
          <Col sm={8} xs={12}>
            <div className="StaySummaryScreen-box3">
              <div>
                <div className="text-type-4">Check-in</div>
                <div className="text-type-3">18 Jan 2024</div>
              </div>
              <div>
                <HiOutlineArrowLongRight className="StaySummaryScreen-icon" />
              </div>
              <div>
                <div className="text-type-4">Checkout</div>
                <div className="text-type-3">18 Jan 2024</div>
              </div>
              <div>
                <div className="text-type-4">Room No</div>
                <div className="text-type-3">303</div>
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <div className="StaySummaryScreen-box4">
          <div className="StaySummaryScreen-box5">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((item: any) => {
              return <Item key={item} />;
            })}
          </div>
        </div>
      </div>
      <br />
      <div>
        <Row>
          <Col sm={2} xs={1}>
            <div className="back-button" onClick={() => props.goBack()}>
              <FaArrowLeft />
            </div>
          </Col>
          <Col sm={4} xs={12} onClick={() => props.sendEmail()}>
            <div className="button-secondary">EMAIL INVOICE</div>
          </Col>
          <Col sm={6} xs={12} onClick={() => props.onChange()}>
            <div className="button-primary">
              <div>PAY & CHECKOUT</div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Step1;
