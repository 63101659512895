import { IoIosRadioButtonOff } from "react-icons/io";
import { IoRadioButtonOn } from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";
function Stepper(props: any) {
  return (
    <div className="reviewScreen-Stepper">
      <div
        className={
          props.step === 1 || props.step === 2 || props.step > 2
            ? "reviewScreen-StepperItem active"
            : "reviewScreen-StepperItem"
        }
      >
        {props.step === 1 || props.step === 2 ? (
          <IoRadioButtonOn className="reviewScreen-StepperIcon" />
        ) : props.step > 2 ? (
          <IoIosCheckmarkCircle className="reviewScreen-StepperIcon" />
        ) : (
          <IoIosRadioButtonOff className="reviewScreen-StepperIcon" />
        )}
        <div>Review </div>
      </div>
      <div className="reviewScreen-line" />
      <div
        className={
          props.step === 3 || props.step > 3
            ? "reviewScreen-StepperItem active"
            : "reviewScreen-StepperItem"
        }
      >
        {props.step === 3 ? (
          <IoRadioButtonOn className="reviewScreen-StepperIcon" />
        ) : props.step > 3 ? (
          <IoIosCheckmarkCircle className="reviewScreen-StepperIcon" />
        ) : (
          <IoIosRadioButtonOff className="reviewScreen-StepperIcon" />
        )}
        <div>Payment</div>
      </div>
      <div className="reviewScreen-line" />
      <div
        className={
          props.step === 4
            ? "reviewScreen-StepperItem active"
            : "reviewScreen-StepperItem"
        }
      >
        {props.step === 4 ? (
          <IoRadioButtonOn className="reviewScreen-StepperIcon" />
        ) : (
          <IoIosRadioButtonOff className="reviewScreen-StepperIcon" />
        )}
        <div>Check-in</div>
      </div>
    </div>
  );
}
export default Stepper;
