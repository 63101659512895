import { LiaUserSolid } from "react-icons/lia";
import { CiWallet } from "react-icons/ci";
import { CiCircleCheck } from "react-icons/ci";
import { PiWarningCircleLight } from "react-icons/pi";
function Card2(props: any) {
  return (
    <div className="StayDetailScreen-box4">
      <div className="StayDetailScreen-box6">
        <div className="StayDetailScreen-box5">
          <div>
            <LiaUserSolid className="StayDetailScreen-icon1" />
          </div>
          <div className="StayDetailScreen-txt3">2 Adults</div>
        </div>
        {props.status === "1" ? (
          <div className="StayDetailScreen-box10">
            <CiCircleCheck />
            &nbsp; Verified
          </div>
        ) : (
          <div className="StayDetailScreen-box11">
            <PiWarningCircleLight />
            &nbsp; ID pending - Acc Guest
          </div>
        )}
      </div>
      <div className="StayDetailScreen-devider" />
      <div className="StayDetailScreen-box6">
        <div className="StayDetailScreen-box5">
          <div>
            <CiWallet className="StayDetailScreen-icon1" />
          </div>
          <div className="StayDetailScreen-txt3">S$1000</div>
        </div>
        {props.status === "1" ? (
          <div className="StayDetailScreen-box10">
            <CiCircleCheck />
            &nbsp; Paid
          </div>
        ) : (
          <div className="StayDetailScreen-box11">
            <PiWarningCircleLight />
            &nbsp; Unpaid
          </div>
        )}
      </div>
    </div>
  );
}

export default Card2;
