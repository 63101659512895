import "./styles.scss";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Banners from "../../components/banners";
import Stepper from "./components/stepper";

import CardAuth from "../../components/cardAuth";
import SuccessPopup from "../../components/successPopup";
import SignPopup from "../../components/signPopup";

import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import Step4 from "./components/step4";

function ReviewScreen(props: any) {
  const Navigate = useNavigate();
  const getOrientation = () => window.screen.orientation.type;
  const [orientation, setOrientation] = useState(getOrientation());
  const [step, setStep] = useState(1);
  const [payment, setPayment] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sign, setSign] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", updateOrientation);
    return () => {
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  const updateOrientation = (event: any) => {
    setOrientation(getOrientation());
  };

  const onClickNext = () => {
    setPayment(true);
    setTimeout(() => {
      setPayment(false);
      setSuccess(true);
      gotoNext4();
    }, 3000);
  };

  const gotoNext4 = () => {
    setTimeout(() => {
      setSuccess(false);
      setStep(4);
    }, 2000);
  };

  const onFinish = () => {
    setSign(true);
  };

  const completed = () => {
    Navigate("/success/2");
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col
            sm={orientation === "portrait-primary" || step === 4 ? 12 : 8}
            md={orientation === "portrait-primary" || step === 4 ? 12 : 8}
            style={{ margin: 0, padding: 0 }}
          >
            <div className="box1">
              <Stepper step={step} />
              <br />
              {step === 1 ? (
                <Step1
                  onBack={() => Navigate(-1)}
                  onChange={() => setStep(2)}
                />
              ) : null}
              {step === 2 ? (
                <Step2 onBack={() => setStep(1)} onChange={() => setStep(3)} />
              ) : null}
              {step === 3 ? (
                <Step3
                  onBack={() => setStep(2)}
                  onChange={() => onClickNext()}
                />
              ) : null}
              {step === 4 ? (
                <Step4 onBack={() => setStep(3)} onChange={() => onFinish()} />
              ) : null}
            </div>
          </Col>
          {orientation === "portrait-primary" || step === 4 ? null : (
            <Col sm={4} md={4} style={{ margin: 0, padding: 0 }}>
              <div className="box2">
                <Banners />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      {payment ? (
        <CardAuth
          visible={payment}
          onChange={(err: any) => {
            console.log("err");
          }}
        />
      ) : null}
      {success ? (
        <SuccessPopup
          visible={success}
          onChange={(err: any) => {
            console.log("err");
          }}
        />
      ) : null}
      {sign ? (
        <SignPopup visible={sign} onChange={(err: any) => completed()} />
      ) : null}
    </div>
  );
}

export default ReviewScreen;
