import { Modal } from "antd";
import Error from "../../assets/images/error.png";
function ErrorPopup(props: any) {
  return (
    <Modal
      open={props.visible}
      footer={false}
      closable={false}
      centered
      width={650}
    >
      <div className="ErrorPopup">
        <img src={Error} className="ErrorPopup-img" />
        <br /> <br />
        <div className="text-type-1">
          Oops, your session is about to expire.
        </div>
        <br /> <br />
        <div style={{ width: "80%" }}>
          <div className="button-primary" onClick={() => props.onChange()}>
            <div>I’M HERE!</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ErrorPopup;
