import { useState, useEffect } from "react";
import "./styles.scss";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import videos from "../../assets/images/bgvid1.mp4";

import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";

function PassportScanScreen(props: any) {
  const Navigate = useNavigate();
  const [step, setstep] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setstep(2);
    }, 2000);
  }, []);

  const onClickNext = () => {
    Navigate("/stayDetails/1");
  };

  return (
    <div className="video-box">
      <video className="video-play" autoPlay loop muted>
        <source src={videos} type="video/mp4" />
        <source src={videos} type="video/ogg" />
      </video>
      <div className="video-overlay"></div>
      <div className="PassportScanScreen">
        {step === 1 ? <Step1 /> : null}
        {step === 2 ? <Step2 /> : null}
        {step === 3 ? <Step3 onChange={() => onClickNext()} /> : null}

        <div className="PassportScanScreen-box2">
          <div onClick={() => Navigate(-1)} className="back-button">
            <FaArrowLeft />
          </div>
          {step === 2 ? (
            <div className="PassportScanScreen-box3">
              <div
                className="PassportScanScreen-button"
                onClick={() => Navigate("/stayDetails/1")}
              >
                matched
              </div>
              <div
                className="PassportScanScreen-button"
                onClick={() => setstep(3)}
              >
                not matched
              </div>
              <div
                className="PassportScanScreen-button"
                onClick={() => setstep(3)}
              >
                semi match multiple search results
              </div>
            </div>
          ) : null}
        </div>
        <br />
      </div>
    </div>
  );
}

export default PassportScanScreen;
