import { useEffect, useState } from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import Card1 from "./components/card1";
import Card2 from "./components/card2";
import Card3 from "./components/card3";
import Banners from "../../components/banners";
import KeyCardActivation from "../../components/keyCardActivation";
import ErrorPopup from "../../components/errorPopup";

function StayDetailScreen(props: any) {
  const Navigate = useNavigate();
  const { status } = useParams();
  const getOrientation = () => window.screen.orientation.type;
  const [orientation, setOrientation] = useState(getOrientation());
  const [keyCard, setkeyCard] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", updateOrientation);
    return () => {
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  const updateOrientation = (event: any) => {
    setOrientation(getOrientation());
  };

  const clickContinue = () => {
    if (status === "1") {
      setkeyCard(true);
      setTimeout(() => {
        setkeyCard(false);
        setError(true);
      }, 5000);
    } else {
      Navigate("/review");
    }
  };

  const ClickNext = () => {
    Navigate("/success/1");
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col
            sm={orientation === "portrait-primary" ? 12 : 8}
            md={orientation === "portrait-primary" ? 12 : 8}
            style={{ margin: 0, padding: 0 }}
          >
            <div className="box1">
              <div className="text-type-1">Hi! Stephen Bradley,</div>
              <div className="text-type-2">
                <div style={{ width: "50%" }}>
                  Welcome to Pullman Hotels & Resorts! Please complete your
                  pending processes and finish your check-in.
                </div>
              </div>
              <div className="StayDetailScreen-box3">
                <Card1 />
                <Card2 status={status} />
                <Card3 />
              </div>
              <div>
                <Row>
                  <Col sm={2} xs={1}>
                    <div className="back-button" onClick={() => Navigate(-1)}>
                      <FaArrowLeft />
                    </div>
                  </Col>
                  <Col sm={8} xs={11}>
                    <div
                      className="button-primary"
                      onClick={() => clickContinue()}
                    >
                      <div>CONTINUE</div>
                    </div>
                  </Col>
                  <Col sm={2}></Col>
                </Row>
              </div>
            </div>
          </Col>
          {orientation === "portrait-primary" ? null : (
            <Col sm={4} md={4} style={{ margin: 0, padding: 0 }}>
              <div className="box2">
                <Banners />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      {keyCard ? (
        <KeyCardActivation
          visible={keyCard}
          onClose={() => setkeyCard(false)}
          onChange={(err: any) => {
            console.log("err");
          }}
        />
      ) : null}
      {error ? (
        <ErrorPopup
          visible={error}
          onClose={() => setError(false)}
          onChange={(err: any) => ClickNext()}
        />
      ) : null}
    </div>
  );
}

export default StayDetailScreen;
