import { Checkbox, Modal } from "antd";
import SignatureCanvas from "react-signature-canvas";
function SignPopup(props: any) {
  return (
    <Modal
      open={props.visible}
      footer={false}
      closable={false}
      centered
      width={750}
    >
      <div className="KeyCardActivation-Box">
        <div className="text-type-1">Finish your Check-in</div>
        <div className="text-type-2">
          Finish checking in by adding your e-signature
        </div>
        <br />
        <div style={{ width: "100%" }}>
          <Checkbox>
            I agree to receive updates and special offers for Pullman Hotels
            products and services.
          </Checkbox>
          <br /> <br />
          <Checkbox>
            I have read, understood and agree to the Rules & Regulations, Data
            Protection & Privacy Laws.
          </Checkbox>
        </div>
        <br />
        <br />
        <div className="signBox" style={{ width: "100%" }}>
          <div className="text-type-2">SIGN HERE</div>

          <br />
          <SignatureCanvas
            penColor="green"
            canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
          />
        </div>
        <br />
        <div style={{ width: "70%" }}>
          <div className="button-primary" onClick={() => props.onChange()}>
            <div>CONFIRM CHECK-IN</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SignPopup;
