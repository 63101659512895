import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FitToViewport } from "react-fit-to-viewport";
import useWindowDimensions from "./utils/useWindowDimensions";

import HomeScreen from "./screens/homeScreen";
import FaceIdScreen from "./screens/faceIdScreen";
import StayDetailScreen from "./screens/stayDetailScreen";
import PassportScanScreen from "./screens/passportScanScreen";
import SuccesScreen from "./screens/succesScreen";
import CheckoutScreen from "./screens/checkoutScreen";
import StaySummaryScreen from "./screens/staySummaryScreen";
import RatingScreen from "./screens/ratingScreen";
import ReviewScreen from "./screens/reviewScreen";
import { ConfigProvider } from "antd";
function App() {
  const { height, width } = useWindowDimensions();
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Raleway-Medium",
          colorPrimary: "#77406f",
          borderRadius: 10,
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route index element={<HomeScreen />} />;
          <Route path="/" element={<HomeScreen />} />;
          <Route path="/faceID/:status" element={<FaceIdScreen />} />;
          <Route path="/stayDetails/:status" element={<StayDetailScreen />} />
          ;
          <Route path="/success/:status" element={<SuccesScreen />} />;
          <Route path="/scanPassport" element={<PassportScanScreen />} />;
          <Route path="/checkout" element={<CheckoutScreen />} />;
          <Route path="/staySummary" element={<StaySummaryScreen />} />;
          <Route path="/rating" element={<RatingScreen />} />;
          <Route path="/review" element={<ReviewScreen />} />;
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
