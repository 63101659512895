import { Modal, Form, Input } from "antd";

function EmailInvoice(props: any) {
  return (
    <Modal
      open={props.visible}
      footer={false}
      onCancel={() => props.onClose()}
      centered
      width={600}
    >
      <div className="KeyCardActivation-Box">
        <div className="text-type-1">Email Invoice</div>
        <div className="text-type-2">
          Confirm your email ID to receive the invoice
        </div>
        <br />
        <div style={{ width: "80%" }}>
          <Form>
            <div className="Form-item">
              <Form.Item noStyle>
                <Input
                  bordered={false}
                  placeholder="Eg:steven.bradley@gmail.com"
                  size="large"
                />
              </Form.Item>
            </div>
          </Form>
          <br /> <br /> <br />
          <div className="button-primary">CONFIRM</div>
        </div>
      </div>
    </Modal>
  );
}

export default EmailInvoice;
