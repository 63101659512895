import PassportScan from "../../../assets/images/passport-scan.png";
function Step1(props: any) {
  return (
    <div className="PassportScanScreen-Step1">
      <div className="text-type-1">Scan Your Passport</div>
      <div className="text-type-2">
        <div style={{ width: "70%" }}>
          Position your document within the scanner located at the center of the
          desk.Hold the scanner for few seconds till green light blinks on the
          scanner.
        </div>
      </div>
      <div className="PassportScanScreen-box1">
        <img
          src={PassportScan}
          className="PassportScanScreen-scanImg"
          alt="passport"
        />
      </div>
    </div>
  );
}

export default Step1;
