import { Col, Row } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";

function Step4(props: any) {
  return (
    <>
      <div className="text-type-1">Review & Sign</div>
      <div className="text-type-2">
        Please review and confirm your details to complete the check-in process.
      </div>
      <br />
      <div className="reviewScreen-box5">
        <Row>
          <Col sm={6}>
            <div className="reviewScreen-box2">
              <div style={{ width: "100%" }}>
                <div className="text-type-3">Stay Information</div>
                <br />
                <Row>
                  <Col sm={6}>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Booking ID</div>
                      <div className="text-type-4">HJSB100F</div>
                    </div>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Check-in Date</div>
                      <div className="text-type-4">18 Jan, 2024</div>
                    </div>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Check-in Date</div>
                      <div className="text-type-4">18 Jan, 2024</div>
                    </div>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Check-in Date</div>
                      <div className="text-type-4">18 Jan, 2024</div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Room No</div>
                      <div className="text-type-4">303</div>
                    </div>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Checkout Date</div>
                      <div className="text-type-4">20 Jan, 2024</div>
                    </div>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Rate</div>
                      <div className="text-type-4">S$ 250.00</div>
                    </div>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Rate Type</div>
                      <div className="text-type-4">Bed & Breakfast</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <br />
            <div className="reviewScreen-box2">
              <div style={{ width: "100%" }}>
                <div className="text-type-3">Credit Card Information</div>
                <br />
                <Row>
                  <Col sm={6}>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Card Number</div>
                      <div className="text-type-4">8******8800</div>
                    </div>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Card Type</div>
                      <div className="text-type-4">VISA</div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Card Holder Name</div>
                      <div className="text-type-4">20/11/2034</div>
                    </div>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Expiry Date</div>
                      <div className="text-type-4">20/11/2034</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <div className="reviewScreen-box2" style={{ height: "100%" }}>
              <div style={{ width: "100%", height: "100%" }}>
                <div className="text-type-3">Guest Information</div>
                <br />
                <div className="text-type-6">Steven Bradley</div>
                <br />
                <div className="reviewScreen-box4">
                  <div className="text-type-5">Email</div>
                  <div className="text-type-4">steve*******@gmail.com</div>
                </div>
                <div className="reviewScreen-box4">
                  <div className="text-type-5">Phone Number</div>
                  <div className="text-type-4">+971 ******186</div>
                </div>
                <Row>
                  <Col sm={6}>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Document Number</div>
                      <div className="text-type-4">8******8800</div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Document Expiry</div>
                      <div className="text-type-4">20/11/2034</div>
                    </div>
                  </Col>
                </Row>
                <div className="reviewScreen-box4">
                  <div className="text-type-5">Phone Number</div>
                  <div className="text-type-4">+971 ******186</div>
                </div>
                <hr />
                <div className="text-type-6">Alice Bradly</div>
                <br />
                <Row>
                  <Col sm={6}>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Document Number</div>
                      <div className="text-type-4">8******8800</div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="reviewScreen-box4">
                      <div className="text-type-5">Email</div>
                      <div className="text-type-4">20/11/2034</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <br /> <br />
        <Row>
          <Col sm={2} xs={1}>
            <div className="back-button" onClick={() => props.onBack()}>
              <FaArrowLeft />
            </div>
          </Col>
          <Col sm={8} xs={12}>
            <div className="button-primary" onClick={() => props.onChange()}>
              <div>PROCEED TO SIGN</div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Step4;
