import "./styles.scss";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Banners from "../../components/banners";
import CardActive from "../../assets/images/card_activate.mp4";
import { FaArrowLeft } from "react-icons/fa6";
import { HiArrowLongRight } from "react-icons/hi2";
import CaptureIcon from "../../assets/images/captureIcon.svg";
function CheckoutScreen(props: any) {
  const Navigate = useNavigate();
  const getOrientation = () => window.screen.orientation.type;
  const [orientation, setOrientation] = useState(getOrientation());

  useEffect(() => {
    window.addEventListener("resize", updateOrientation);
    return () => {
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  const updateOrientation = (event: any) => {
    setOrientation(getOrientation());
  };
  return (
    <div>
      <Container fluid>
        <Row>
          <Col
            sm={orientation === "portrait-primary" ? 12 : 8}
            md={orientation === "portrait-primary" ? 12 : 8}
            style={{ margin: 0, padding: 0 }}
          >
            <div className="box1">
              <div className="text-type-1">Place Your Key</div>
              <div className="text-type-2">
                Place your keycard on the Kiosk to get started
              </div>
              <div className="CheckoutScreen-box1">
                <video className="KeyCardActivation-video" autoPlay loop muted>
                  <source src={CardActive} type="video/mp4" />
                  <source src={CardActive} type="video/ogg" />
                </video>
                <br />
                <div className="CheckoutScreen-button">OR</div>
              </div>

              <br />
              <div>
                <Row>
                  <Col sm={2} xs={1}>
                    <div className="back-button" onClick={() => Navigate(-1)}>
                      <FaArrowLeft />
                    </div>
                  </Col>
                  <Col sm={8} xs={11}>
                    <div
                      className="button-primary"
                      onClick={() => Navigate("/staySummary")}
                    >
                      <div>
                        <img
                          src={CaptureIcon}
                          className="FaceIdScreen-CaptureIcon"
                        />
                      </div>
                      <div>CAPTURE MY FACE</div>
                      <div>
                        <HiArrowLongRight className="FaceIdScreen-BsArrowRight" />
                      </div>
                    </div>
                  </Col>

                  <Col sm={2}></Col>
                </Row>
              </div>
            </div>
          </Col>
          {orientation === "portrait-primary" ? null : (
            <Col sm={4} md={4} style={{ margin: 0, padding: 0 }}>
              <div className="box2">
                <Banners />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default CheckoutScreen;
