import { Modal } from "antd";
import CardActive from "../../assets/images/card_activate.mp4";
function CardAuth(props: any) {
  return (
    <Modal
      open={props.visible}
      footer={false}
      closable={false}
      centered
      width={650}
    >
      <div className="KeyCardActivation-Box">
        <div className="text-type-1">Card Authorisation</div>
        <video className="KeyCardActivation-video" autoPlay loop muted>
          <source src={CardActive} type="video/mp4" />
          <source src={CardActive} type="video/ogg" />
        </video>
        <div className="text-type-2">
          <div style={{ width: "60%" }}>
            Insert you card into the payment terminal on the top right of this
            Kiosk.
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CardAuth;
