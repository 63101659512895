import { Form, Input } from "antd";
import { Col, Row } from "react-bootstrap";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";

function Step1(props: any) {
  return (
    <>
      <div className="text-type-1">Identity Verification</div>
      <div className="text-type-2">
        Please review and update your details as needed.
      </div>
      <br />
      <div className="reviewScreen-box1">
        <div className="text-type-4">Primary Guest</div>
        <div className="reviewScreen-box2">
          <div className="text-type-3">Steven Bradley</div>
          <div>
            <FaCheckCircle className="Success-icon2" />
          </div>
        </div>
        <br />
        <div className="text-type-4">Accompanying Guest</div>
        <div className="reviewScreen-box2">
          <div style={{ width: "100%" }}>
            <div className="text-type-3">Guest 2</div>
            <br />
            <div>
              <Form>
                <Row>
                  <Col sm={6}>
                    <div>First Name *</div>
                    <div className="Form-item">
                      <Form.Item noStyle>
                        <Input placeholder="—" bordered={false} size="large" />
                      </Form.Item>
                    </div>
                    <br />
                    <div>Last Name *</div>
                    <div className="Form-item">
                      <Form.Item noStyle>
                        <Input placeholder="—" bordered={false} size="large" />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div>Document Number *</div>
                    <div className="Form-item">
                      <Form.Item noStyle>
                        <Input placeholder="—" bordered={false} size="large" />
                      </Form.Item>
                    </div>
                    <br />
                    <div>Email *</div>
                    <div className="Form-item">
                      <Form.Item noStyle>
                        <Input placeholder="—" bordered={false} size="large" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div>
        <Row>
          <Col sm={2} xs={1}>
            <div className="back-button" onClick={() => props.onBack()}>
              <FaArrowLeft />
            </div>
          </Col>
          <Col sm={8} xs={12}>
            <div className="button-primary" onClick={() => props.onChange()}>
              <div>SCAN TO ADD DETAILS</div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Step1;
